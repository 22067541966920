import { useState, useEffect } from "react"
import { getRandomAvatar } from "../utils/avatarGenerator"

interface CardRankProps {
  imageUrl: string
  altText: string
  name: string
  nbKill: number
  position: number
}

export const CardRank: React.FC<CardRankProps> = ({
  imageUrl,
  altText,
  name,
  nbKill,
  position,
}) => {
  const [img, setImg] = useState<string>("");
  useEffect(() => {
    setImg(imageUrl && imageUrl !== "" ? `./images/players/${imageUrl}.jpg` : getRandomAvatar())
  }, []);
  return (
    <div className="cardRank">
      <img
        src={img}
        alt={altText}
        className="cardRank__img"
      />
      <div className="cardRank__name">
        <p className="cardRank--text">
          {name}
        </p>
        <p className="cardRank--text__sm">{nbKill} kill</p>
      </div>
      <div className="cardRank--stick">

        <p className="cardRank--text">
          {position}
        </p>
      </div>
    </div>
  )
}
