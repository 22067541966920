import { Banner } from "./Banner"

interface PopUpAlerteProps {
    message: string
}

export const PopUpAlerte = ({ message }: PopUpAlerteProps) => {
    return (
        <div className="popUpAlerte">
            <div className="popUpAlerte--container">
                <img src="./images/megaphone.svg" alt="megaphone en 3D" className="decoration" />
                <div className="alarme" />
                <div className="popUpAlerte--text">
                    <Banner message={message} user={""} />
                    <p>Une oblitération potentielle ?</p>
                </div>
            </div>
        </div>
    )
}