import { create } from "zustand";
import { GameService } from "../../services/Game.service";
import { GameState } from "../interfaces/Game.interface.store";

const useGameStore = create<GameState>((set) => {
    const gameService = new GameService();

    return {
        game: null,
        error: null,

        startGame: async (gameName) => {
            try {
                const game = await gameService.startGame({ name: gameName });
                set({ game, error: null });
            } catch (error) {
                if (error instanceof Error) {
                    set({ error });
                } else {
                    set({ error: new Error('An unknown error occurred') });
                }
            }
        },

        stopGame: async () => {
            try {
                const game = await gameService.stopGame();
                set({ game, error: null });
            } catch (error) {
                if (error instanceof Error) {
                    set({ error });
                } else {
                    set({ error: new Error('An unknown error occurred') });
                }
            }
        },

        getStartedGame: async () => {
            try {
                const game = await gameService.getStartedGame();
                set({ game, error: null });
            } catch (error) {
                if (error instanceof Error) {
                    set({ error });
                } else {
                    set({ error: new Error('An unknown error occurred') });
                }
            }
        },
    };
});

export default useGameStore;