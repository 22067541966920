import { useMemo } from 'react'
import { RankingDto } from '../services/dtos/RankingDto'
import { ItemPodium } from './ItemPodium'

interface PodiumProps {
  items: RankingDto[]
}

export const Podium: React.FC<PodiumProps> = ({ items }) => {
  const [restructuredItems] = useMemo(() => {
    const restructuredItems = [items[1], items[0], items[2]].filter(Boolean)
    return [restructuredItems]
  }, [items])

  return (
    <div className="podium">
      {restructuredItems.map(
        (item, index) =>
          item && (
            <ItemPodium
              key={index}
              index={index}
              imageUrl={item.username}
              altText="avatar"
              position={item.ranking}
              name={item.username}
              nbKill={item.nbKill}
            />
          ),
      )}
    </div>
  )
}
