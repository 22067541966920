import { ErrorResponse, errorManagement, typedFetch } from "../utils/typedFetch";
import { RankingDto } from "./dtos/RankingDto"; // Adjust the import path as needed

export class RankingService {
    apiSlug = "/ranking";
    API_URL = "https://killer-api.sunnyteam.ovh/api";

    async getGameRanking(gameId: number): Promise<RankingDto[]> {
        const response: number | RankingDto[] | ErrorResponse = await typedFetch<RankingDto[] | ErrorResponse>(`${this.API_URL}${this.apiSlug}/${gameId}`);
        return errorManagement<RankingDto[]>(response);
    }
}