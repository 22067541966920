import React, { useEffect, useState } from 'react';
import useWebSocket from './useWebSocket';
import useRankingStore from './stores/selectors/Ranking.selector.store';
import useGameStore from './stores/selectors/Game.selector.store';
import { RankingDto } from './services/dtos/RankingDto';
import { Podium } from './components/Podium';
import { CardRank } from './components/CardRank';
import { Banner } from './components/Banner';
import { PopUpAlerte } from './components/PopUpAlerte';

const getRandomPhrase = (): string => {
  const funnySentences: string[] = [
    "s'est fait pulvériser",
    "a été catapulté dans une autre dimension",
    "a subi une désintégration express",
    "s'est vu offrir un aller simple pour l'au-delà",
    "a été réduit en miettes cosmiques",
    "s'est transformé en confettis stellaires",
    "a reçu un express pour le néant",
    "a fait un plongeon dans le vide interstellaire",
    "a été effacé de l'existence avec zèle",
    "s'est évaporé dans le cosmos"
  ];
  const randomIndex = Math.floor(Math.random() * funnySentences.length);
  return funnySentences[randomIndex];
};

const App: React.FC = () => {
  const message = useWebSocket('wss://wss.killer-ws.sunnyteam.ovh'); // URL du serveur WebSocket
  const { rankings, getGameRanking } = useRankingStore();
  const { game, getStartedGame } = useGameStore();
  const [podium, setPodium] = useState<RankingDto[]>([])
  const [dateKill, setDateKill] = useState<Date>(new Date())
  const [messageDisplayed, setMessageDisplayed] = useState<string>("");

  function interpolateColor(color1: string, color2: string, factor: number) {
    if (arguments.length < 3) {
      factor = 0.5;
    }
    let result = color1.slice(1).match(/.{2}/g)!.map((hex, index) => {
      return Math.round(parseInt(hex, 16) * (1 - factor) + parseInt(color2.slice(1).match(/.{2}/g)![index], 16) * factor);
    });

    return '#' + result.map(x => x.toString(16).padStart(2, '0')).join('');
  }

  const colors: string[] = [];
  for (let i = 0; i <= 60; i++) {
    colors.push(interpolateColor('#F095FF', '#003366', i / 60));
  }

  const changeDynamicColor = (newColor: string) => {
    document.documentElement.style.setProperty('--colorPrimary', newColor);
  };

  useEffect(() => {
    let colorIndex = 0;
    let direction = 1; // 1 pour avancer, -1 pour reculer

    const intervalId = setInterval(() => {
      changeDynamicColor(colors[colorIndex]);

      // Mise à jour de l'index pour le prochain intervalle
      if (colorIndex === colors.length - 1) direction = -1; // Inverse la direction à la fin
      else if (colorIndex === 0) direction = 1; // Inverse la direction au début

      colorIndex += direction;
    }, 10000); // Change toutes les secondes

    return () => clearInterval(intervalId); // Nettoie l'intervalle à la fin du cycle de vie du composant
  }, []);

  useEffect(() => {
    getStartedGame();
  }, []);

  useEffect(() => {
    if (rankings.length > 0) {
      const podiumData = rankings.slice(0, 3)
      setPodium(podiumData)
    }
  }, [rankings])

  useEffect(() => {
    if (game) {
      getGameRanking(game.id);
      if (message !== "") {
        if (message.includes("detectKill:")) {
          setMessageDisplayed(`Un kill a été détecté`);
          playAudios(true);
        } else {
          setDateKill(new Date());
          playAudios();
          setMessageDisplayed(`${getRandomPhrase()}, le ${formattedDate} à ${formattedHour}`);
        }
      }
    }
  }, [game, message]);

  const playAudios = (detectKill: boolean = false) => {
    const urlPolice = './songs/police.mp3';
    const audioObjPolice = new Audio(urlPolice);

    let urlSentencePrimary = `./songs/personnalized/${message}.mp3`;
    const urlSentenceFallback = './songs/obliteration.mp3';
    if (detectKill) {
      urlSentencePrimary = `./songs/personnalized/killdetected.mp3`;
    }

    const audioObjSentence = new Audio(urlSentencePrimary);

    audioObjSentence.onerror = () => {
      audioObjSentence.src = urlSentenceFallback;
      audioObjSentence.load();
    };

    audioObjPolice.play();
    audioObjPolice.onended = () => audioObjSentence.play();
  };

  const formattedDate = dateKill.toLocaleString('fr-FR', {
    day: 'numeric',
    month: 'numeric',
  });
  const formattedHour = dateKill.toLocaleString('fr-FR', {
    hour: 'numeric',
    minute: 'numeric',
  });


  return (
    <main>
      <section className='sectionTitle'>
        {message && message !== ""
          ?
          <Banner user={message === "detectKill:" ? "" : message} message={messageDisplayed} />
          :
          <Banner user='' message="Puisse le sort vous être favorable" />
        }
      </section>
      <section className='sectionRank'>
        <Podium items={podium} />
        <div className="listCardRank">
          {rankings.map((r, index) => {
            if (index < 3) return null
            return (
              <CardRank
                imageUrl={r?.username}
                altText="avatar"
                position={r.ranking}
                name={r.username}
                nbKill={r.nbKill}
                key={r.username}
              />
            )
          })}
        </div>
      </section>
      {
        message.includes("detectKill:") &&
        (<PopUpAlerte message={messageDisplayed} />)
      }
    </main>
  );
};

export default App;
