export const typedFetch = async <T>(
  url: string,
  options?: RequestInit
): Promise<T | number> => {
  try {
    const res = await fetch(url, options);

    if (res.headers.get('content-type')?.includes('application/json')) {
      const data = await res.json();
      return data;
    } else {
      // Si la réponse n'est pas du JSON, renvoyer le code de statut
      return res.status;
    }
  } catch (error) {
    if (typeof error === "string") throw new Error(error);
    else throw error;
  }
};

export interface ErrorResponse {
  message: string;
  error: string;
  statusCode: number;
}

export const errorManagement = <T extends object>(response: number | T | ErrorResponse): T => {
  if (typeof response === 'number') {
    throw new Error("Une erreur est survenue");
  } else if ('error' in response && 'statusCode' in response && 'message' in response) {
    throw new Error(`Erreur : ${response.error}, Message : ${response.message}, Code : ${response.statusCode}`);
  } else {
    // At this point, response must be of type T
    return response as T;
  }
}
