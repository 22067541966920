import React from "react";

interface BannerProps {
  user: string;
  message: string;
}

export const Banner = ({ message, user }: BannerProps) => {
  return (
    <div className="banner">
      <div className="banner__content">
        <p><span>{`${user} `}</span>{message}</p>
        <div className="rectangle"></div>
        <p><span>{`${user} `}</span>{message}</p>
        <div className="rectangle"></div>
        <p><span>{`${user} `}</span>{message}</p>
        <div className="rectangle"></div>
        <p><span>{`${user} `}</span>{message}</p>
        <div className="rectangle"></div>
        <p><span>{`${user} `}</span>{message}</p>
        <div className="rectangle"></div>
        <p><span>{`${user} `}</span>{message}</p>
        <div className="rectangle"></div>
        <p><span>{`${user} `}</span>{message}</p>
        <div className="rectangle"></div>
        <p><span>{`${user} `}</span>{message}</p>
        <div className="rectangle"></div>
        <p><span>{`${user} `}</span>{message}</p>
        <div className="rectangle"></div>
        <p><span>{`${user} `}</span>{message}</p>
        <div className="rectangle"></div>
        <p><span>{`${user} `}</span>{message}</p>
        <div className="rectangle"></div>
        <p><span>{`${user} `}</span>{message}</p>
        <div className="rectangle"></div>
        <p><span>{`${user} `}</span>{message}</p>
        <div className="rectangle"></div>
      </div>
    </div>
  );
}
