import { useEffect, useMemo, useState } from 'react'
import { getRandomAvatar } from '../utils/avatarGenerator'

interface ItemPodiumProps {
  index: number
  position: number
  imageUrl: string
  altText: string
  name: string
  nbKill: number
}

export const ItemPodium: React.FC<ItemPodiumProps> = ({
  index,
  position,
  imageUrl,
  altText,
  name,
  nbKill,
}) => {
  const [img, setImg] = useState<string>("");
  useEffect(() => {
    setImg(imageUrl && imageUrl !== "" ? `./images/players/${imageUrl}.jpg` : getRandomAvatar())
  }, []);


  const positionClass = useMemo(() => {
    if (index === 1) {
      return '--colorPrimary'
    } else {
      return '--colorDark'
    }
  }, [position])

  const scaleClass = useMemo(() => {
    if (index !== 1) {
      return 'scale(0.75)'
    }
  }, [position])

  return (
    <div className='itemPodium'>
      <div className="itemPodium--content"
        style={{ transform: `${scaleClass}` }}
      >
        <div className="itemPodium--content__img" >
          <img src={img} alt={altText} />
        </div>
        <div className="itemPodium--content__position">
          <p
            style={{ color: `var(${positionClass})` }}
          >
            {position}
          </p>
        </div>
        <p className="itemPodium--content__name">
          {name}
        </p>
        <p className="itemPodium--content__nbKill">{nbKill} kill</p>
      </div>
    </div>
  )
}
