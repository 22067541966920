import { create } from "zustand";
import { RankingService } from "../../services/Ranking.service";
import { RankingState } from "../interfaces/Ranking.interface.store";

const useRankingStore = create<RankingState>((set) => {
    const rankingService = new RankingService();

    return {
        rankings: [],
        error: null,

        getGameRanking: async (gameId) => {
            try {
                const rankings = await rankingService.getGameRanking(gameId);
                set({ rankings, error: null });
            } catch (error) {
                if (error instanceof Error) {
                    set({ error });
                } else {
                    set({ error: new Error('An unknown error occurred') });
                }
            }
        },
    };
});

export default useRankingStore;