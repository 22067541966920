import { useEffect, useState, useRef } from 'react';

const useWebSocket = (url: string) => {
    const [message, setMessage] = useState<string>("");
    const socketRef = useRef<WebSocket | null>(null);

    useEffect(() => {
        // Fonction pour initialiser la connexion WebSocket
        const connectWebSocket = () => {
            socketRef.current = new WebSocket(url);

            socketRef.current.onmessage = (event) => {
                setMessage(event.data);
                console.log(event, event.data);
            };

            socketRef.current.onclose = () => {
                console.log("WebSocket déconnecté, tentative de reconnexion...");
                setTimeout(connectWebSocket, 3000); // Reconnecter après 3 secondes
            };

            socketRef.current.onerror = (error) => {
                console.error("Erreur WebSocket:", error);
                socketRef.current?.close(); // Fermer la connexion en cas d'erreur
            };
        };

        connectWebSocket();

        return () => {
            socketRef.current?.close();
        };
    }, [url]);

    return message.replace("Kill signalé: ", "");
};

export default useWebSocket;
