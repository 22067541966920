import { ErrorResponse, errorManagement, typedFetch } from "../utils/typedFetch";
import { GameDto, StartGameDto } from "./dtos/GameDtos";

export class GameService {
    apiSlug = "/game";
    API_URL = "https://killer-api.sunnyteam.ovh/api";

    async startGame(startGameDto: StartGameDto): Promise<GameDto | null> {
        const response: number | GameDto | ErrorResponse = await typedFetch<GameDto | ErrorResponse>(`${this.API_URL}${this.apiSlug}/startGame`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(startGameDto)
        });

        return errorManagement<GameDto>(response);
    }

    async stopGame(): Promise<GameDto | null> {
        const response: number | GameDto | ErrorResponse = await typedFetch<GameDto | ErrorResponse>(`${this.API_URL}${this.apiSlug}/stopGame`, {
            method: 'PUT'
        });

        return errorManagement<GameDto>(response);
    }

    async getStartedGame(): Promise<GameDto | null> {
        const response: number | GameDto | ErrorResponse = await typedFetch<GameDto | ErrorResponse>(`${this.API_URL}${this.apiSlug}/getStartedGame`, {
            method: 'PUT'
        });

        return errorManagement<GameDto>(response);
    }
}
